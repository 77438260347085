"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./app");
require('jquery-mask-plugin');
var moment = require("moment");
var $ = require('jquery');
$(function () {
    $('.date').mask('00/00/0000');
    $('.money').mask("#.##0,00", { reverse: true });
    $('.new-historico').on('click', function (e) {
        e.preventDefault();
        var collection = document.getElementsByClassName('row-historico');
        var len = collection.length;
        var html = '<div class="panel radius" id="historico_%d">'
            + ' <h3>Histórico</h3>'
            + ' <div class="row row-historico">'
            + '   <div class="large-2 columns">'
            + '     <label>Data'
            + '       <input type="text" name="historicos[' + len + '][dtMovimento]" class="historico_dt_movimento date" pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])/(0[1-9]|1[012])/[0-9]{4}" />'
            + '     </label>'
            + '   </div>'
            + '   <div class="large-2 columns">'
            + '     <label>Tp. Equip.'
            + '       <select name="historicos[' + len + '][tpEquipamento]" class="historico_tp_equipamento">'
            + '         <option value="" selected=""></option>'
            + '         <option value="Solar">Solar</option>'
            + '         <option value="Elétrico">Elétrico</option>'
            + '         <option value="gas">Gás</option>'
            + '       </select>'
            + '     </label>'
            + '   </div>'
            + '   <div class="large-6 columns">'
            + '     <label>Equipamento'
            + '       <input type="text" name="historicos[' + len + '][equipamento]" class="historico_equipamento" />'
            + '     </label>'
            + '   </div>'
            + '   <div class="large-2 columns">'
            + '     <label>Operação'
            + '       <select name="historicos[' + len + '][tpOperacao]" class="historico_operacao">'
            + '         <option value="" selected=""></option>'
            + '         <option value="V">Venda</option>'
            + '         <option value="M">Manutenção</option>'
            + '         <option value="I">Instalação</option>'
            + '       </select>'
            + '     </label>'
            + '   </div>'
            + ' </div>'
            + ' <div class="row">'
            + '   <div class="large-12 columns">'
            + '     <button type="button" id="historico_bt_new_ocorrencia_%d" style="margin-top: 2em;" class="button tiny new-ocorrencia">Ocorrência</button>'
            + '     <button type="button" id="historico_bt_new_recebimento_%d" style="margin-top: 2em;" class="button tiny new-recebimento">Recebimento</button>'
            + '     <button type="button" id="historico_bt_remove_%d" style="margin-top: 2em;" class="button tiny alert delete-historico">Remover histórico</button>'
            + '   </div>'
            + ' </div>'
            + ' <div class="ocorrencias">'
            + '   <h6>Ocorrência(s)</h6>'
            + ' </div>'
            + ' <div class="recebimentos">'
            + '   <h6>Recebimento(s)</h6>'
            + ' </div>'
            + '</div>';
        html = html.replace(/%d/g, len);
        $('.historicos').append(html);
        collection = document.getElementsByClassName('historico_dt_movimento');
        collection[collection.length - 1].value = moment().format("DD/MM/YYYY");
        $('.date').mask('00/00/0000');
    });
    $(document).on('click', '.new-ocorrencia', function (e) {
        e.preventDefault();
        var rowId = $(e.target).attr('id');
        var id = rowId.substr(rowId.lastIndexOf('_'));
        var nId = id.replace(/\D/g, '');
        var collection = document.getElementsByClassName('row-ocorrencia');
        var len = collection.length;
        var html = '<div class="panel" id="ocorrencia_%d">'
            + '<div class="row row-ocorrencia">'
            + '<div class="small-2 columns">'
            + '  <label>Data'
            + '    <input type="text" name="historicos[' + nId + '][ocorrencias][' + len + '][dtVisita]" class="ocorrencia_dt_visita date" pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])/(0[1-9]|1[012])/[0-9]{4}" />'
            + '  </label>'
            + '</div>'
            + '<div class="large-2 columns">'
            + '  <label>Tp. Visita'
            + '    <select name="historicos[' + nId + '][ocorrencias][' + len + '][tpVisita]" class="ocorrencia_tp_visita">'
            + '      <option value="" selected=""></option>'
            + '      <option value="N">Normal</option>'
            + '      <option value="GV">Garantia de Venda</option>'
            + '      <option value="GC">Garantia de Conserto</option>'
            + '    </select>'
            + '  </label>'
            + '</div>'
            + '<div class="large-2 columns">'
            + '  <label>Situação'
            + '    <select name="historicos[' + nId + '][ocorrencias][' + len + '][situacao]" class="ocorrencia_situacao">'
            + '      <option value="" selected=""></option>'
            + '      <option value="C">Concluído</option>'
            + '      <option value="A">Aberto</option>'
            + '      <option value="SC">Sem Conserto</option>'
            + '      <option value="SP">Sem Peça p/ Reposição</option>'
            + '      <option value="R">Retirado p/ Conserto</option>'
            + '      <option value="T">Terceiros</option>'
            + '    </select>'
            + '  </label>'
            + '</div>'
            + '<div class="large-4 columns">'
            + '  <label>Observação'
            + '    <textarea name="historicos[' + nId + '][ocorrencias][' + len + '][obs]" class="ocorrencia_obs"></textarea>'
            + '  </label>'
            + '</div>'
            + '<div class="large-2 columns">'
            + '  <button type="button" id="ocorrencia_bt_remove_%d" style="margin-top: 2em;" class="button tiny alert delete-ocorrencia">Remover Ocorrência</button>'
            + '</div>'
            + '</div>'
            + '</div>';
        html = html.replace(/%d/g, len);
        $('#historico' + id + ' .ocorrencias').append(html);
        $('.date').mask('00/00/0000');
    });
    $(document).on('click', '.new-recebimento', function (e) {
        e.preventDefault();
        var rowId = $(e.target).attr('id');
        var id = rowId.substr(rowId.lastIndexOf('_'));
        var nId = id.replace(/\D/g, '');
        var collection = document.getElementsByClassName('row-recebimento');
        var len = collection.length;
        var html = '<div class="panel" id="recebimento_%d">'
            + '<div class="row row-recebimento">'
            + '<div class="small-2 columns">'
            + '  <label>Vencimento'
            + '    <input type="text" name="historicos[' + nId + '][recebimentos][' + len + '][dtVencimento]" class="recebimento_dt_vencimento date" pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])/(0[1-9]|1[012])/[0-9]{4}" />'
            + '  </label>'
            + '</div>'
            + '<div class="small-2 columns">'
            + '  <label>N° Parcela'
            + '    <input type="text" name="historicos[' + nId + '][recebimentos][' + len + '][nParcela]" class="recebimento_n_parcela" />'
            + '  </label>'
            + '</div>'
            + '<div class="small-2 columns">'
            + '  <label>Valor'
            + '    <input type="text" name="historicos[' + nId + '][recebimentos][' + len + '][valor]" class="recebimento_valor money" />'
            + '  </label>'
            + '</div>'
            + '<div class="large-6 columns">'
            + '  <label>Observação'
            + '    <textarea name="historicos[' + nId + '][recebimentos][' + len + '][obs]" class="recebimento_obs"></textarea>'
            + '  </label>'
            + '</div>'
            + '<div class="large-1 columns">'
            + '  <label>Recebido'
            + '    <select name="historicos[' + nId + '][recebimentos][' + len + '][pago]" class="recebimento_pago" required>'
            + '      <option value="N" selected="">N</option>'
            + '      <option value="S">S</option>'
            + '    </select>'
            + '  </label>'
            + '</div>'
            + '<div class="small-2 columns">'
            + '  <label>Recebido em'
            + '    <input type="text" name="historicos[' + nId + '][recebimentos][' + len + '][dtRecebimento]" class="recebimento_dt_recebimento date" pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])/(0[1-9]|1[012])/[0-9]{4}" />'
            + '  </label>'
            + '</div>'
            + '<div class="small-2 columns">'
            + '  <label>Valor. Recebido'
            + '    <input type="text" name="historicos[' + nId + '][recebimentos][' + len + '][vlrRecebido]" class="recebimento_vlr_recebido money" />'
            + '  </label>'
            + '</div>'
            + '<div class="large-2 columns">'
            + '  <button type="button" id="recebimento_bt_remove_%d" style="margin-top: 2em;" class="button tiny alert delete-recebimento">Remover Recebimento</button>'
            + '</div>'
            + '</div>'
            + '</div>';
        html = html.replace(/%d/g, len);
        $('#historico' + id + ' .recebimentos').append(html);
        $('.date').mask('00/00/0000');
        $('.money').mask("#.##0,00", { reverse: true });
    });
    $(document).on('click', '.delete-historico', function (e) {
        e.preventDefault();
        var rowId = $(e.target).attr('id');
        var id = rowId.substr(rowId.lastIndexOf('_'));
        $('#historico' + id).remove();
    });
    $(document).on('click', '.delete-ocorrencia', function (e) {
        e.preventDefault();
        var rowId = $(e.target).attr('id');
        var id = rowId.substr(rowId.lastIndexOf('_'));
        $('#ocorrencia' + id).remove();
    });
    $(document).on('click', '.delete-recebimento', function (e) {
        e.preventDefault();
        var rowId = $(e.target).attr('id');
        var id = rowId.substr(rowId.lastIndexOf('_'));
        $('#recebimento' + id).remove();
    });
});
